import React, { useState, useEffect, ReactNode } from "react";
import { connect } from "react-redux";
import { get } from "lodash";
import { FormikActions } from "formik";
import { Log } from 'ng2-logger';
import * as Types from '../../../store/types';
import * as Actions from '../../../store/actions/general';
import Paginate from "../paginate";
import SortedColumn from "../sorted-column";
import Filter, { FilterComponent, IFilterTable } from "./filter";
import { getConstFromType } from "../../../pages/sketches/tools/utils";
import { Action, BuildingRoom, TableColumn, TableType } from "../../../pages/sketches/tools/interfaces";
import cn, { flexCenter } from "../../ui/Tailwind";
import * as Constants from '../../../store/constants/all';
import { fallbackImage1, IAvailablePlace } from "../../../pages/event-period/constants";
import Spinner from "../../templates/spinner";
import Translator from "../../../services/translate-factory";
import Button from "../../button";
import { Badge } from "../../ui/Badge";
import { FaLockOpen, FaLock } from "react-icons/fa";
import { Image } from "antd";

const L = Log.create('SketchTable');

type ResultType = Types.IBuildingItem | Types.ICampusItem | BuildingRoom | IAvailablePlace | { [key: string]: any };

interface TableProps extends Types.IPageProps {
    columnComponent?: TableColumn[];
    filterComponent?: FilterComponent[]
    actions?: Action[];
    results?: Array<ResultType>;
    filters?: Types.IFilterCampus | Types.IFilterBuilding | IFilterTable;
    selectOptions?: Array<Types.ISelectOption>
    initialFilter?: Types.IFilterCampus | Types.IFilterBuilding
    type?: TableType;
    rowClassname?: string
    tableClassname?: string
    dispatch?: any
    disableFetch?: boolean
    disableFetchPagination?: boolean
    spinner?: string,
    notFoundMessage?: ReactNode,
    requestOnCreate?: boolean,
    isLocaleSort?: boolean
    pageSize?: number

}

function Table({
    columnComponent,
    filterComponent,
    results,
    filters,
    initialFilter,
    type,
    rowClassname,
    tableClassname,
    dispatch,
    disableFetchPagination = false,
    spinner,
    notFoundMessage,
    actions,
    isLocaleSort = false,
    requestOnCreate = false,
    pageSize = 10,
}: Readonly<TableProps>) {
    const T = new Translator();

    const [currentPage, setCurrentPage] = useState(1);
    const [prevFilter, setPrevFilter] = useState<Types.IFilterCampus | Types.IFilterBuilding | undefined>(undefined);
    const [allIds, setAllIds] = useState<number[]>([]);
    const [selectedIds, setSelectedIds] = useState<number[]>([]);

    //#region Clientside pagination
    const handlePageChange = (page: number) => {
        setCurrentPage(page);
    };

    const getPaginatedResults = () => {

        const startIndex = (currentPage - 1) * pageSize;
        // Eğer silinenler de geliyorsa bunları filtrelemek için bunu kullan -> results.filter((image) => !image.deleted)
        return (results || []).slice(startIndex, startIndex + pageSize);
    };
    //#endregion

    useEffect(() => {
        if (prevFilter !== initialFilter || requestOnCreate) {
            searchTable(initialFilter);
            setPrevFilter(initialFilter);
        }
    }, []);


    const onFormikSubmit = (model: IFilterTable, FormActions: FormikActions<Types.IFilterCampus>) => {
        searchTable(model);
        FormActions.setSubmitting(false);
    }

    const onFormReset = () => {
        searchTable(initialFilter);
    };

    const searchTable = (newFilters: any) => {
        if (!type || !getConstFromType[type]) {
            return;
        }

        const { search, spin } = getConstFromType[type];
        dispatch(Actions.ApiRequest(search, newFilters, spin));
    }

    const onPageChange = (page: number) => {
        const updatedFilters = { ...filters, page: page };
        searchTable(updatedFilters);
    };

    const sort = (sortkey: string, order_by: string) => {
        const updatedFilters = { ...filters, order_by: `${sortkey}_${order_by}` };
        searchTable(updatedFilters);
    };

    const localeSort = (sortkey: string, order_by: string) => {

        return (results || []).sort((a: any, b: any) => {
            if (a[sortkey] < b[sortkey]) {
                return order_by === 'asc' ? -1 : 1;
            }
            if (a[sortkey] > b[sortkey]) {
                return order_by === 'asc' ? 1 : -1;
            }
            return 0;
        })
    }


    const checkAllIdsSelected = (): boolean => {
        let result: boolean = false;
        if (allIds.length && selectedIds.length) {
            result = allIds.every((item: number) => selectedIds.indexOf(item) !== -1);
        }
        return result;
    };

    const onSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!type || !getConstFromType[type]) {
            return;
        }
        const { search, spin } = getConstFromType[type];

        if (e && e.currentTarget) {
            if (e.currentTarget.checked) {
                const updatedFilters = { ...filters, select_all: true };
                dispatch(
                    Actions.ApiRequest(
                        search,
                        updatedFilters,
                        spin,
                        (response: any) => {
                            setAllIds(response.all_ids || [])
                            setSelectedIds(response.all_ids || [])
                        }
                    )
                );
            } else {
                setAllIds([])
                setSelectedIds([])
                // filters.setSelectAll => false
            }
        }
    };
    const onCheckedItem = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e && e.currentTarget) {
            let checkedList = [...selectedIds];
            let stringID: string = e.currentTarget.dataset.id || '';
            let id = parseInt(stringID, 10);
            if (e.target.checked) {
                checkedList.push(id);
            } else {
                let index = checkedList.indexOf(id);
                if (index !== -1) {
                    checkedList.splice(index, 1);
                }
                // filters.setSelectAll => false
            }
            setSelectedIds(checkedList);
        }
    };

    const renderHeader = () => {
        return (
            <thead>
                <tr>
                    {columnComponent && columnComponent.map((item) => {
                        const columnWidth = item.widthPercentage ? `${item.widthPercentage}%` : 'auto';
                        switch (item.__headerType) {
                            case 'default':
                                return item.title === ""
                                    ? <th key={item.parseName} style={{ width: columnWidth }}></th>
                                    : <th key={item.parseName} scope="col" className="text-center" style={{ width: columnWidth }}>
                                        {item.title}
                                    </th>

                            case 'sort':
                                return <SortedColumn
                                    key={item.parseName}
                                    datacell={item.parseName}
                                    className="d-none d-lg-table-cell d-xl-table-cell text-center"
                                    title={item.title || item.parseName}
                                    sortkey={item.parseName}
                                    sortedcolumn={filters && filters.order_by}
                                    sort={isLocaleSort ? localeSort : sort}
                                    style={{ width: columnWidth }}
                                />
                            case 'checkAll':
                                return <th data-cell="select" style={{ width: columnWidth }}>
                                    <div className="tick-radio position-relative">
                                        <input
                                            id='select_all'
                                            type="checkbox"
                                            className="form-radio"
                                            checked={checkAllIdsSelected()}
                                            onChange={onSelectAll}
                                        />
                                    </div>
                                </th>
                            default:
                                return <th key={item.parseName}></th>;
                        }
                    })}
                </tr>
            </thead>
        )
    };

    const translateValue = (value: string | Types.ICampusItem | Types.IBuildingItem, headerItem: TableColumn) => {
        if (!headerItem.translateMap)
            return value

        const translateMap = headerItem.translateMap.find((item) => item.value === value)
        if (translateMap) {
            return translateMap.label
        }
        return value
    }

    const renderCell = (item: Types.ICampusItem | Types.IBuildingItem | Record<string, any>, headerItem: TableColumn) => {
        if (headerItem.customRender) {
            return headerItem.customRender(item)
        }
        const parsedValue = (item as Record<string, any>)[headerItem.parseName]



        switch (headerItem.__componentType) {
            case 'oneLine': {
                const title = translateValue(parsedValue, headerItem)
                return (
                    <td key={headerItem.parseName} className="text-center">
                        {title || '-'}
                    </td>
                );
            }
            case 'multiLine':
                return getMultiLine(headerItem, parsedValue)
            case "image":
                return getImage(item, headerItem, parsedValue)
            case 'chexbox':
                return getCheckbox(headerItem, parsedValue)
            case 'activeness':
                return getActiveness(headerItem, parsedValue)

            default:
                return <td key={headerItem.parseName}></td>;
        }
    };

    const getMultiLine = (headerItem: TableColumn, parsedValue: any[]) => {
        const items = parsedValue || [];
        return (
            <td key={headerItem.parseName} className="text-center">
                <div className="table-scrollable-td">
                    {items.length > 0 ?
                        items.map((val: Types.ICampusItem | Types.IBuildingItem, index: number) => {
                            const value = val.name || val;
                            const title = translateValue(value, headerItem);
                            return (
                                <div>
                                    {title}
                                    {index < items.length - 1 ? ', ' : ''}
                                    <br />
                                </div>
                            );
                        }) : '-'}

                </div>
            </td>
        );

    }
    const getImage = (item: Types.ICampusItem | Types.IBuildingItem | Record<string, any>, headerItem: TableColumn, parsedValue: string) => {
        if (!headerItem.customImageSrc) return <td></td>
        return <td className='text-center'>
            <div>
                <Image style={{ width: '100%', height: '100%', maxWidth: '22rem', maxHeight: "14rem", borderRadius: "0.25rem", objectFit: 'cover' }} preview={false} src={headerItem.customImageSrc(item)} fallback={fallbackImage1} />
            </div>
        </td>
    }
    const getCheckbox = (headerItem: TableColumn, parsedValue: string) => {
        return (
            <td key={headerItem.parseName} data-cell="select" className="text-center">
                <div className="tick-radio position-relative">
                    <input
                        id={parsedValue}
                        data-id={parsedValue}
                        type="checkbox"
                        className="form-radio"
                        checked={
                            selectedIds &&
                            selectedIds.indexOf(Number(parsedValue) || -1) > -1
                        }
                        onChange={onCheckedItem}
                    />
                </div>
            </td>
        );

    }

    const getActiveness = (headerItem: TableColumn, parsedValue: boolean) => {
        return (
            <td key={headerItem.parseName} className="text-center">
                <div className={cn(flexCenter)}>
                    <Badge
                        _color={parsedValue ? 'green' : 'red'}
                        className="tw-py-2 text-center"
                        title={parsedValue ? T.t('gen_active') : T.t('gen_passive')}
                    >
                        {parsedValue ? <FaLockOpen /> : <FaLock />}
                    </Badge>
                </div>
            </td>
        );
    }

    const getResult = () => (disableFetchPagination
        ? getPaginatedResults()
        : results || []
    )
    const renderRows = () => (
        <tbody>
            {
                getResult().map((item: ResultType) => {
                    return (
                        <tr
                            data-title={item.name} className={rowClassname}>
                            {columnComponent && columnComponent.map((headerItem) => renderCell(item, headerItem))}
                        </tr>
                    )
                })}
            {isEmpty && (
                <tr className="tw-h-48">
                    <td colSpan={columnComponent && columnComponent.length} className="text-center tw-align-middle">
                        <span className="tw-text-base">
                            {notFoundMessage || T.t('gen_no_records_found')}
                        </span>
                    </td>
                </tr>
            )}
        </tbody>
    );

    const onSelectedAction = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (!type || !getConstFromType[type]) return

        const { spin } = getConstFromType[type];

        let model = {
            selected_type: 0,
            ids: selectedIds,
            status_images: 0
        }

        switch (type) {
            case TableType.SKETCH_CAMPUS:
                model.selected_type = 1;
                break;
            case TableType.SKETCH_BUILDING:
                model.selected_type = 2;
                break;
        }

        if (e && e.target) {
            let constant: string = '';
            let title: string = '';
            let body: string = '';
            switch (e.currentTarget.dataset.actiontype) {
                case 'delete':
                    // onSelectedAction constant
                    constant = Constants.campus.CAMPUS_DELETE;
                    title = T.t('gen_delete_action');
                    body = T.t('gen_delete_campus_question');
                    break;
                case 'active/passive':
                    if (e.currentTarget.dataset.active === '1') {
                        title = T.t('gen_confirm_activation');
                        body = T.t('gen_active_n_question');
                        constant = Constants.sketch.SKETCH_BULK_UPDATE;
                        model.status_images = 1;
                    } else if (e.currentTarget.dataset.active === '0') {
                        title = T.t('gen_confirm_passivation');
                        body = T.t('gen_passive_n_question');
                        constant = Constants.sketch.SKETCH_BULK_UPDATE;
                        model.status_images = 0;
                    }
                    break;
                default:
                    break;
            }

            dispatch(
                Actions.ShowModal({
                    title: title,
                    body: body,
                    name: title,
                    icon: 'warning',
                    iconColor: 'red',
                    confirm: T.t('gen_yes'),
                    cancel: T.t('gen_cancel'),
                    onConfirm: () => {
                        const resultCallback = (status: number) => {
                            if (status == 200) {
                                setSelectedIds([])
                                searchTable(initialFilter)
                            }
                        };

                        dispatch(
                            Actions.ApiRequest(constant, model, spin, resultCallback)
                        );
                    }
                })
            );
        }
    };

    const isEmpty = getResult().length <= 0

    const SelectedActionButton = ({ action }: { action: Action }) => {

        if (action.actionType === 'delete') {

            return <Button color="red" data-actiontype={action.actionType} onClick={onSelectedAction}>
                {
                    action.actionType === 'delete'
                        ? T.t("gen_delete")
                        : `${T.t('gen_active')}/${T.t('gen_passive')}`
                }
            </Button>
        }
        else if (action.actionType === 'active/passive') {
            return <>
                <Button icon="lock_open" color="pastelGreen" data-actiontype={action.actionType} data-active={1} onClick={onSelectedAction}>
                    {T.t('gen_active')}
                </Button>
                <Button icon="lock" color="pastelRed" data-actiontype={action.actionType} data-active={0} onClick={onSelectedAction}>
                    {T.t('gen_passive')}
                </Button>

            </>
        }
        return <></>
    }

    const actionMapper = (action: Action) => {
        if (action.actionType === 'active/passive' || action.actionType === 'delete') {
            return <SelectedActionButton action={action} />
        }
        return (
            <Button
                key={action.title}
                color={action.color}
                icon={action.icon}
                onClick={() => action.actionHandler && action.actionHandler(selectedIds)}
            >
                {action.title}
            </Button>
        )
    }
    const renderTableActions = () => {
        return <div className="tw-grid tw-grid-flow-col tw-gap-2">
            {
                selectedIds.length > 0 &&
                <>
                    <Button color="pastelGray" icon="close" onClick={() => setSelectedIds([])}>
                        {T.t('gen_cancel_selection')}&nbsp;<b>({selectedIds.length})</b>
                    </Button>
                    {
                        actions && actions.filter(_ => _.showOnSelect).map(actionMapper)
                    }
                </>
            }
            {
                actions && actions.filter(_ => _.showIdle).map(actionMapper)
            }
        </div>
    }

    return (
        <div className="mt-4 white-container"
            style={{ borderRadius: '8px' }}
        >
            {
                filterComponent ?
                    <Filter
                        onFormikSubmit={onFormikSubmit}
                        initialFilter={initialFilter || {
                            page: 1,
                            total: -1,
                            size: 10
                        }}
                        onFormReset={onFormReset}
                        components={filterComponent}
                    >
                        {renderTableActions()}
                    </Filter> : renderTableActions()
            }

            <div className="tw-relative">
                {spinner && <Spinner name={spinner} />}
                <div className="row tw-mt-4">
                    <div className="col-12">
                        <table
                            className={cn(
                                "table aplan-table aplan-table-responsive table-borderless table-striped table-hover sortable filter-table",
                                tableClassname
                            )}
                        >
                            {renderHeader()}
                            {renderRows()}
                        </table>

                        <div className="row-options justify-content-end">
                            <div className="page-sorting d-flex align-items-center justify-content-center" style={{ marginTop: '5px' }}>
                                {
                                    results && results.length > 0
                                    &&
                                    (
                                        disableFetchPagination
                                            ? <Paginate
                                                filters={{
                                                    page: currentPage,
                                                    size: pageSize,
                                                    total: filters && filters.total || results!.length,
                                                }}
                                                onPageChange={handlePageChange}
                                            />
                                            : <Paginate filters={filters} onPageChange={onPageChange} />
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
}

const mapStateToProps = (store: Types.IPersistedState, ownProps: TableProps): TableProps => {
    if (!store || !store.state || ownProps.disableFetch) {
        ownProps.filters = ownProps.initialFilter
        return ownProps;
    }
    const type = ownProps.type as TableType;
    const config = getConstFromType[type];
    if (!config) {
        return ownProps;
    }
    const statePaths = config.statePaths;

    const newProps: any = Object.assign({}, ownProps, {
        term_id: store.state.term_id,
        user: store.state.user,
        results: get(store.state, statePaths.results),
        filters: get(store.state, statePaths.filters),
        selectOptions: get(store.state, statePaths.selectOptions),
        distanceData: get(store.state, statePaths.distanceData),
        term_type: store.state.term_type,
    });

    return newProps;
};

const equal = require('deep-equal');
const areStatesEqual = (next: Types.IPersistedState, prev: Types.IPersistedState) => {
    return equal(prev, next)
};

const dispatchProps = (dispatch: any) => ({
    dispatch
});

const TableBase = connect(mapStateToProps, dispatchProps, null, { areStatesEqual })(Table);

export default TableBase;
