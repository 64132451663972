import React, { useState, ChangeEvent, useEffect, useReducer, useCallback } from 'react';
import { connect } from 'react-redux';
import Translator from '../../services/translate-factory';
import DefinitionPiece from './ui/definition-piece';
import * as Types from '../../store/types';
import * as Actions from '../../store/actions/general';
import * as Constants from '../../store/constants/all';
import { ClassroomTypes, SettingsEventModuleStatus } from '../../store/constants/setting-const';
import { ClassroomUsageStateOptions, SpecialUsingStateOptions, ClassroomUserTypeOptions, ClassroomSeatingArrangementOptions, ClassroomAttributeOptions, ClassroomSeatingTypeOptions, ClassroomCategoryOptions } from '../../store/constants/classroom-const';
import { BuildingFunctionalityOptions } from '../../store/constants/building-const';
import { debounce } from 'lodash';
import { filterClassroomTypesForEventModuleStatus, filterClassroomTypesForEventModuleStatusForItem } from '../../util/sort';

export interface Item {
    id: string | number
    name: string;
    isDeleted: boolean;
}

interface Props {
    setModelSettings: (newStates: any) => void
    values: { [key: string]: any };
    handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
    dispatch: any
    term_id: number;  // state'ten gelen term_id
    term_type: number;  // state'ten gelen term_type
    setFieldValue: any,
    onSubmit: () => void,
    T: Translator,
    general_settings: any
}

export interface DefinitionPieceData {
    items: Item[]
    itemType: string
    stateType: string
    isError: boolean
}

const DefinitionsSettings: React.FC<Props> = ({ onSubmit, setModelSettings, term_id, term_type, dispatch, values, handleChange, setFieldValue, general_settings }) => {
    const T = new Translator();

    const [, forceRender] = useReducer(x => x + 1, 0);
    const handleLanguageChange = useCallback(
        debounce(() => {
            forceRender(1);
        }, 1000),
        []
    );
    useEffect(() => {
        T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, handleLanguageChange);
        T.addListener(Constants.gen.CORE_CHANGE_LANGUAGE, handleLanguageChange);
        return () => {
            T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, handleLanguageChange);
        };
    }, []);

    // derslik/oturma odasi tipi
    const [classroomTypeData, setClassroomTypeData] = useState<DefinitionPieceData>({ items: [], stateType: 'classroom_types', itemType: 'classroom_type', isError: false })
    const [classroomAttributesData, setClassroomAttributesData] = useState<DefinitionPieceData>({ items: [], stateType: 'classroom_attributes', itemType: 'classroom_attribute', isError: false });
    const [buildingFunctionsData, setBuildingFunctionsData] = useState<DefinitionPieceData>({ items: [], stateType: 'building_functionalities', itemType: 'buildingFunction', isError: false });
    const [roomClassroomFunctionsData, setRoomClassroomFunctionsData] = useState<DefinitionPieceData>({ items: [], stateType: 'roomClassroomFunctions', itemType: 'roomClassroomFunction', isError: false });
    const [roomClassroomUsingStatesData, setRoomClassroomUsingStatesData] = useState<DefinitionPieceData>({ items: [], stateType: 'classroom_usage_states', itemType: 'classroom_usage_state', isError: false });
    const [roomClassroomStandingOrderData, setRoomClassroomStandingOrderData] = useState<DefinitionPieceData>({ items: [], stateType: 'classroom_seating_arrangements', itemType: 'classroom_seating_arrangement', isError: false })
    const [classroomSeatingTypeData, setClassroomSeatingTypeData] = useState<DefinitionPieceData>({ items: [], stateType: 'classroom_seating_types', itemType: 'classroom_seating_type', isError: false })
    const [categoryData, setCategoryData] = useState<DefinitionPieceData>({ items: [], stateType: 'classroom_categories', itemType: 'classroom_category', isError: false })
    const [specialUsingData, setSpecialUsingData] = useState<DefinitionPieceData>({
        items: []
        , stateType: 'special_using_states', itemType: 'special_using_state', isError: false
    })
    const [userTypeData, setUserTypeData] = useState<DefinitionPieceData>({
        items: [],
        stateType: 'classroom_user_types', itemType: 'classroom_user_type', isError: false
    })


    //parentta bulunan values'in icerisinden cekilen veriler alt dallara buradan set'lenip gonderiliyor.
    const updateStateFromValues = (
        stateData: DefinitionPieceData,
        setStateFunction: React.Dispatch<React.SetStateAction<DefinitionPieceData>>,
    ) => {
        if (values[stateData.stateType] && values[stateData.stateType] !== JSON.stringify(stateData.items)) {
            if (stateData.stateType === 'classroom_types') {
                setStateFunction((prev) => ({
                    ...prev,
                    items: JSON.parse(values[stateData.stateType]).filter(filterClassroomTypesForEventModuleStatusForItem(general_settings))
                })
                );
            } else {
                setStateFunction((prev) => ({
                    ...prev,
                    items: JSON.parse(values[stateData.stateType])
                }));
            }
        }
    };

    useEffect(() => {
        updateStateFromValues(buildingFunctionsData, setBuildingFunctionsData);
        updateStateFromValues(roomClassroomFunctionsData, setRoomClassroomFunctionsData);
        updateStateFromValues(roomClassroomUsingStatesData, setRoomClassroomUsingStatesData);
        updateStateFromValues(classroomTypeData, setClassroomTypeData);
        updateStateFromValues(roomClassroomStandingOrderData, setRoomClassroomStandingOrderData);
        updateStateFromValues(classroomAttributesData, setClassroomAttributesData);
        updateStateFromValues(classroomSeatingTypeData, setClassroomSeatingTypeData);
        updateStateFromValues(categoryData, setCategoryData);
        updateStateFromValues(userTypeData, setUserTypeData);
        updateStateFromValues(specialUsingData, setSpecialUsingData);
    }, [
        values[buildingFunctionsData.stateType],
        values[roomClassroomFunctionsData.stateType],
        values[roomClassroomUsingStatesData.stateType],
        values[classroomTypeData.stateType],
        values[roomClassroomStandingOrderData.stateType],
        values[classroomAttributesData.stateType],
        values[classroomSeatingTypeData.stateType],
        values[categoryData.stateType],
        values[userTypeData.stateType],
        values[specialUsingData.stateType],
    ]);

    useEffect(() => {
        updateModelSettings(specialUsingData);
    }, [specialUsingData.items]);

    useEffect(() => {
        updateModelSettings(userTypeData);
    }, [userTypeData.items]);

    useEffect(() => {
        updateModelSettings(classroomTypeData);
    }, [classroomTypeData.items]);

    useEffect(() => {
        updateModelSettings(buildingFunctionsData);
    }, [buildingFunctionsData.items]);

    useEffect(() => {
        updateModelSettings(roomClassroomFunctionsData);
    }, [roomClassroomFunctionsData.items]);

    useEffect(() => {
        updateModelSettings(roomClassroomUsingStatesData);
    }, [roomClassroomUsingStatesData.items]);

    useEffect(() => {
        updateModelSettings(roomClassroomStandingOrderData);
    }, [roomClassroomStandingOrderData.items]);

    useEffect(() => {
        updateModelSettings(classroomAttributesData);
    }, [classroomAttributesData.items]);

    useEffect(() => {
        updateModelSettings(classroomSeatingTypeData);
    }, [classroomSeatingTypeData.items]);

    useEffect(() => {
        updateModelSettings(categoryData);
    }, [categoryData.items]);

    const onAddDataRow = (newValue: string, data: DefinitionPieceData, setDataFunction: React.Dispatch<React.SetStateAction<DefinitionPieceData>>, localizedLabels?: (T: Translator) => {
        label: any;
        value: any;
    }[]) => {
        let filteredDatas = data.items.filter((item: any) => !item.isDeleted).map((item: any) => item.name.toLowerCase());
        if (!filteredDatas.includes(newValue.toLowerCase()) && (!localizedLabels || !localizedLabels(T).some(_ => {
            return (_.label as string).toLowerCase() === newValue.toLowerCase();
        }))) {
            let tempCS: Item[] = [
                ...data.items,
                {
                    id: (data.items.length + 1).toString(),
                    name: newValue,
                    isDeleted: false
                }
            ]
            setDataFunction((prev) => ({
                ...prev,
                items: tempCS,
                isError: false
            }));
            setFieldValue(data.itemType, "")
            setFieldValue(data.stateType, JSON.stringify(tempCS))

        } else {
            setDataFunction((prev) => ({ ...prev, isError: true }))
        }
    };

    enum DefinitionDataType {
        CLASSROOM_TYPE,
    }

    // silme isleminde herhangi bir iliski durumu gozlenecekse bu fonksiyon kullanilmali
    function relationalDeleteProcess(
        index: any,
        item: any,
        setTypeData: (value: React.SetStateAction<DefinitionPieceData>) => void,
        data: DefinitionPieceData,
        definitionDataType: DefinitionDataType
    ) {
        let searchFilterModel: any;
        let errorBody: string = "";
        let errorName: string = "";

        if (definitionDataType === DefinitionDataType.CLASSROOM_TYPE) {
            searchFilterModel = createSearchFilterModel(item.id);
            errorBody = T.t('gen_delete_classroom_type_question');
            errorName = 'classroom_type_delete';
        }

        const resultCallback = createResultCallback(index, item, data, setTypeData, errorBody, errorName);
        dispatch(Actions.ApiRequest(Constants.classroom.CLASSROOM_LIST_SEARCH, searchFilterModel, 'classroom-list-spin', resultCallback));
    }

    function createSearchFilterModel(id: any) {
        return {
            classroom_type_ids: [id],
            term_id: term_id,
            select_all: false,
            order_by: '',
            page: 1,
            query: '',
            total: -1,
            size: 10
        } as Types.IFilterClassroom;
    }

    function createResultCallback(
        index: any,
        item: any,
        data: DefinitionPieceData,
        setTypeData: (value: React.SetStateAction<DefinitionPieceData>) => void,
        errorBody: string,
        errorName: string
    ) {
        return (result: any, status: number) => {
            setTimeout(() => {
                if (result && result.data && result.data.length) {
                    onDeleteError(errorBody, errorName);
                } else {
                    const courseResultCallback = createCourseResultCallback(index, item, data, setTypeData, errorBody, errorName);
                    const apiRequestType = term_type === 0 ? Constants.activity.ACTIVITY_LIST_SEARCH : Constants.course.COURSE_LIST_SEARCH;
                    dispatch(Actions.ApiRequest(apiRequestType, createSearchFilterModel(item.id), 'course-list-spin', courseResultCallback));
                }
            }, 1000);
        };
    }

    function createCourseResultCallback(
        index: any,
        item: any,
        data: DefinitionPieceData,
        setTypeData: (value: React.SetStateAction<DefinitionPieceData>) => void,
        errorBody: string,
        errorName: string
    ) {
        return (result: any, status: number) => {
            setTimeout(() => {
                if (result && result.data && result.data.length) {
                    onDeleteError(errorBody, errorName);
                } else {
                    onDeleteDataRow(index, item, data, setTypeData);
                }
            }, 1000);
        };
    }

    const onDeleteError = (body: string, name: string) => {
        dispatch(
            Actions.ShowModal({
                title: T.t('gen_delete_action'),
                body: body,
                name: name,
                icon: 'warning',
                iconColor: 'red',
                cancel: T.t('gen_cancel'),
            })
        );
    };

    // standart delelete process
    const onDeleteDataRow = (index: any, item: any, data: DefinitionPieceData, setTypeData: (value: React.SetStateAction<DefinitionPieceData>) => void) => {
        let newItems = [
            ...data.items.filter((val) => val.id !== item.id),
            {
                id: item.id,
                name: item.name,
                isDeleted: true
            }
        ]
        setTypeData((prev) => ({
            ...prev,
            items: newItems
        }));
        // setFieldValue(data.stateType, JSON.stringify(newItems))

    };


    const updateModelSettings = (data: DefinitionPieceData) => {
        if (data.items.length > 0) {
            setModelSettings({ [data.stateType]: JSON.stringify(data.items) });
        }
    };

    const classroomDefinitionList = [
        {
            id: 1,
            show: true,
            component: <DefinitionPiece
                values={values} setModelSettings={setModelSettings} handleChange={handleChange} onAddItem={onAddDataRow}
                // itemType'in bir ust parenttaki formikte bulunan field ile ayni olmasi gerekiyor
                // Tipleri ve setleme fonksiyonlari
                definitionPiece={classroomTypeData}
                setDefinitionPiece={setClassroomTypeData}
                localizedLabel={ClassroomTypes}
                // iliskisel silme islemi yapildi bu yapilmak zorunda degil burada standart silme islemi de yapilabilir (onDeleteDataRow)
                deleteItem={(index, item, dp, sDp) => relationalDeleteProcess(
                    index,
                    item,
                    sDp,
                    dp,
                    DefinitionDataType.CLASSROOM_TYPE,
                )}
                itemLabel={T.t('gen_classroom_type')}
                enterItemLabel={T.t('gen_enter_classroom_type')}
                aditionalError={T.t('gen_event_module_on_definitions_error')}
            />
        },
        {
            id: 2,
            show: general_settings && general_settings.event_module_status === SettingsEventModuleStatus.Active,
            component: <DefinitionPiece
                values={values} setModelSettings={setModelSettings} handleChange={handleChange} onAddItem={onAddDataRow} deleteItem={onDeleteDataRow}
                definitionPiece={roomClassroomUsingStatesData}
                setDefinitionPiece={setRoomClassroomUsingStatesData}
                itemLabel={T.t('gen_room_classroom_using_state')}
                enterItemLabel={T.t('gen_enter_room_classroom_using_state')}
                localizedLabel={ClassroomUsageStateOptions}
            />,
        },
        {
            id: 3,
            show: general_settings && general_settings.event_module_status === SettingsEventModuleStatus.Active,
            component: <DefinitionPiece
                values={values} setModelSettings={setModelSettings} handleChange={handleChange} onAddItem={onAddDataRow} deleteItem={onDeleteDataRow}
                definitionPiece={roomClassroomStandingOrderData}
                setDefinitionPiece={setRoomClassroomStandingOrderData}
                itemLabel={T.t('gen_room_classroom_seating_arrangement')}
                enterItemLabel={T.t('gen_enter_room_classroom_seating_arrangement')}
                localizedLabel={ClassroomSeatingArrangementOptions}
            />
        },
        {
            id: 4,
            show: general_settings && general_settings.event_module_status === SettingsEventModuleStatus.Active,
            component: <DefinitionPiece
                values={values} setModelSettings={setModelSettings} handleChange={handleChange} onAddItem={onAddDataRow} deleteItem={onDeleteDataRow}
                definitionPiece={classroomAttributesData}
                setDefinitionPiece={setClassroomAttributesData}
                itemLabel={T.t('gen_room_attribute')}
                enterItemLabel={T.t('gen_enter_room_attribute')}
                localizedLabel={ClassroomAttributeOptions}
            />,
        },
        {
            id: 5,
            show: general_settings && general_settings.event_module_status === SettingsEventModuleStatus.Active,
            component: <DefinitionPiece
                values={values} setModelSettings={setModelSettings} handleChange={handleChange} onAddItem={onAddDataRow} deleteItem={onDeleteDataRow}
                definitionPiece={classroomSeatingTypeData}
                setDefinitionPiece={setClassroomSeatingTypeData}
                itemLabel={T.t('gen_classroom_room_seating_type')}
                enterItemLabel={T.t('gen_enter_classroom_room_seating_type')}
                localizedLabel={ClassroomSeatingTypeOptions}
            />,
        },
        {
            id: 6,
            show: general_settings && general_settings.event_module_status === SettingsEventModuleStatus.Active,
            component: <DefinitionPiece
                values={values} setModelSettings={setModelSettings} handleChange={handleChange} onAddItem={onAddDataRow} deleteItem={onDeleteDataRow}
                definitionPiece={categoryData}
                setDefinitionPiece={setCategoryData}
                itemLabel={T.t('gen_classroom_category')}
                enterItemLabel={T.t('gen_enter_category')}
                localizedLabel={ClassroomCategoryOptions}
            />,
        },
        {
            id: 7,
            show: general_settings && general_settings.event_module_status === SettingsEventModuleStatus.Active,
            component: <DefinitionPiece
                values={values} setModelSettings={setModelSettings} handleChange={handleChange} onAddItem={onAddDataRow} deleteItem={onDeleteDataRow}
                definitionPiece={userTypeData}
                setDefinitionPiece={setUserTypeData}
                itemLabel={T.t('gen_user_type')}
                enterItemLabel={T.t('gen_enter_user_type')}
                localizedLabel={ClassroomUserTypeOptions}
            />,
        },
        {
            id: 8,
            // TODO: show: general_settings && general_settings.event_module_status === SettingsEventModuleStatus.Active,
            show: false,
            component: <DefinitionPiece
                values={values} setModelSettings={setModelSettings} handleChange={handleChange} onAddItem={onAddDataRow} deleteItem={onDeleteDataRow}
                definitionPiece={specialUsingData}
                setDefinitionPiece={setSpecialUsingData}
                itemLabel={T.t('gen_special_using_state')}
                enterItemLabel={T.t('gen_enter_special_using_state')}
                localizedLabel={SpecialUsingStateOptions}
            />,
        },
    ]

    const buildingDefinitionList = [
        {
            id: 1,
            show: general_settings && general_settings.event_module_status === SettingsEventModuleStatus.Active,
            component: <DefinitionPiece
                values={values} setModelSettings={setModelSettings} handleChange={handleChange} onAddItem={onAddDataRow} deleteItem={onDeleteDataRow}
                definitionPiece={buildingFunctionsData}
                setDefinitionPiece={setBuildingFunctionsData}
                itemLabel={T.t('gen_building_functionalities')}
                enterItemLabel={T.t('gen_enter_building_functionalities')}
                localizedLabel={BuildingFunctionalityOptions}
            />,
        },
    ]

    return (
        <>
            {classroomDefinitionList.filter(item => item.show).length > 0 &&
                <div>
                    <h4>{T.t("gen_classroom")}</h4>
                    <hr />
                    {
                        classroomDefinitionList
                            .filter(item => item.show)
                            .map((item, index) => (
                                index % 2 === 0 ? (
                                    <>
                                        <div className="row" key={item.id}>
                                            {item.component}
                                            {classroomDefinitionList[index + 1] && classroomDefinitionList[index + 1].show && (
                                                <>{classroomDefinitionList[index + 1].component}</>
                                            )}
                                        </div>
                                        <br />
                                        <br />
                                    </>
                                ) : null
                            ))
                    }
                </div>}
            {buildingDefinitionList
                .filter(item => item.show).length > 0
                && <div>
                    <h4>{T.t("gen_building")}</h4>
                    <hr />
                    {
                        buildingDefinitionList
                            .filter(item => item.show)
                            .map((item, index) => (
                                index % 2 === 0 ? (
                                    <>
                                        <div className="row" key={item.id}>
                                            {item.component}
                                            {buildingDefinitionList[index + 1] && buildingDefinitionList[index + 1].show && (
                                                <>{buildingDefinitionList[index + 1].component}</>
                                            )}
                                        </div>
                                        <br />
                                        <br />
                                    </>
                                ) : null
                            ))
                    }
                </div>
            }
            <br />
            <hr />
            <div className="row">
                <div className="col-12">
                    <button id='button_save' type="button" className="btn btn-green" onClick={onSubmit}>
                        <i className="material-icons mr-2">save</i> {T.t('gen_save')}
                    </button>
                </div>
            </div>
        </>
    );

};

const dispatchProps = (dispatch: any) => ({ dispatch });

const mapStateToProps = (store: Types.IPersistedState, ownProps: any): any => {
    if (!store) {
        return ownProps;
    }
    const newProps: any = Object.assign({}, ownProps, {
        results: store.state.building_page && store.state.building_page.results,
        selectOptions: store.state.select_options && store.state.select_options.coursePage,
        term_id: store.state.term_id,
        term_type: store.state.term_type,
        settings: store.state.settings,
        general_settings: store.state.general_settings,
    });
    return newProps;
};

const equal = require('deep-equal');
const areStatesEqual = (next: Types.IPersistedState, prev: Types.IPersistedState) => {
    return equal(prev, next);
};

const container = connect(mapStateToProps, dispatchProps, null, {
    areStatesEqual
})(DefinitionsSettings);

export default container;

