import { Item } from "../pages/setting/defitinions-settings"
import { SettingsEventModuleStatus } from "../store/constants/setting-const"
import { ISelectOption } from "../store/types"

export const filterClassroomTypesForEventModuleStatus = (general_settings: any) => {
    return (item: ISelectOption) => general_settings.event_module_status === SettingsEventModuleStatus.Active
        ? (general_settings.event_module_status !== SettingsEventModuleStatus.Active ? item.value >= 0 : true)
        : item.value >= 0
}
export const filterClassroomTypesForEventModuleStatusForItem = (general_settings: any) => {
    return (item: Item) => general_settings.event_module_status === SettingsEventModuleStatus.Active
        ? (general_settings.event_module_status !== SettingsEventModuleStatus.Active ? item.id >= 0 : true)
        : item.id >= 0
}