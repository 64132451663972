import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { Formik, FormikActions, FormikProps } from 'formik';
import { ValueType } from 'react-select/lib/types';
import { Log } from 'ng2-logger';

import * as Actions from '../../store/actions/general';
import * as Constants from '../../store/constants/all';
import * as Types from '../../store/types';
import { AvailableFormRoles, UserSearchInitialValues, DisplayRoleLabel } from '../../store/constants/user-const';
import { SettingsEventModuleStatus } from '../../store/constants/setting-const';
import { routes as Routes } from '../../store/constants/routes';
import * as GT from '../../tools/general-tools';
import UserForm from './user-form';
import Spinner from '../../components/templates/spinner';
import MainLayout from '../layouts/main-layout';
import SimplePage from '../../components/templates/simple-page';
import APlanHeader from '../../components/templates/aplan-header';
import SortedColumn from '../../components/table/sorted-column';
import Paginate from '../../components/table/paginate';
import ImportModal, { ExcelImportKeys } from '../../components/excel-imports/import-modal';

import Translator from '../../services/translate-factory';
const T = Translator.create();
const L = Log.create('UserListPage');

class UserTable extends Component<Types.IUserPageProps, Types.IUserPageState> {
  state: Types.IUserPageState = {
    filterIsOpen: false,
    selectMenuIsOpen: true,
    userFormIsOpen: false,
    filters: Object.assign({}, UserSearchInitialValues),
    selectedUserIds: [],
    groupSelectorIsOpen: false,
    userId: '',
    all_ids: [],
    selected_ids: []
  };

  langChanged = () => {
    setTimeout(() => {
      try {
        this.forceUpdate();
      } catch (e) {
        L.error(e as string);
      }
    }, 1000);
  };

  componentDidMount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    T.addListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    window.scrollTo(0, 0);
    // if (this.props.term_id != -1) {
    //   this.props.dispatch(Actions.SetTermInfo(-1, -1));
    //   this.props.dispatch(Actions.Notification('gen_selected_term_removed', 'gen_warning', 'warning'))
    // }
    this.init();
  }

  componentDidUpdate() {
    // if (this.props.term_id != -1) {
    //   this.props.dispatch(Actions.SetTermInfo(-1, -1));
    //   this.props.dispatch(Actions.Notification('gen_selected_term_removed', 'gen_warning', 'warning'))
    //   this.init();
    // }
  }

  init() {
    this.getFilterProgramsByFacultiesAtUsers([]);
    this.userSelectOptions();
    this.searchUsers();
  }

  componentWillUnmount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
  }

  searchUsers() {
    this.state.filters.status = [1, 0]
    this.props.dispatch(Actions.ApiRequest(Constants.user.USER_LIST_SEARCH, this.state.filters, 'user-list-spin'));
  }

  getFilterProgramsByFacultiesAtUsers = (facultyIds: any) => {
    this.props.dispatch(Actions.ApiRequest(Constants.user.USER_GET_FILTER_PROGRAMS_BY_FACULTIES, facultyIds, 'user-list-spin'));
  }

  userSelectOptions() {
    this.props.dispatch(Actions.ApiRequest(Constants.user.USER_SELECT_OPTIONS));
  }

  onUserImported = () => {
    this.searchUsers();
  };

  sort = (sortkey: string, order_by: string) => {
    this.state.filters.order_by = sortkey + '_' + order_by;
    this.setState(this.state);
    this.searchUsers();
  };

  userFormIsOpen = () => {
    this.props.dispatch(Actions.Navigation(GT.Route(Routes.USERS, '/create')));
  };

  userFormOnClose = (refresh: boolean) => {
    if (this.state.userFormIsOpen) {
      this.props.dispatch(Actions.Navigation(GT.Route(Routes.USERS)));
    }
    if (refresh) {
      this.init();
    }
  };
  onPageChange = (page: number) => {
    this.state.filters.page = page;
    this.setState(this.state);
    this.searchUsers();
  };

  onFormReset = () => {
    this.state.filters = Object.assign({}, UserSearchInitialValues);
    this.setState(this.state);
    this.searchUsers();
  };

  onFilterUser(model: Types.IUserPagedQuery, FormActions: FormikActions<Types.IUserPagedQuery>) {
    this.state.filters = model;
    this.state.filters.page = 1;
    this.state.filterIsOpen = true;
    this.setState(this.state);
    this.searchUsers();
    FormActions.setSubmitting(false);
  }

  onUserEdit = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (e && e.currentTarget) {
      const id: string = e.currentTarget.dataset.id || '';
      this.props.dispatch(Actions.Navigation(GT.Route(Routes.USERS, '/' + id)));
    }
  };

  onSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e && e.currentTarget) {
      if (e.currentTarget.checked && this.props.results) {
        this.state.filters.select_all = true;
        this.setState(this.state);
        this.props.dispatch(
          Actions.ApiRequest(
            Constants.user.USER_LIST_SEARCH,
            this.state.filters,
            'user-list-spin',
            (response: any) =>
              this.setState({
                ...this.state,
                all_ids: response.all_ids,
                selected_ids: response.all_ids
              })
          )
        );
      } else {
        this.setState({
          ...this.state,
          all_ids: [],
          selected_ids: [],
          filters: {
            ...this.state.filters,
            select_all: false
          }
        });
      }
    }
  };

  checkAllIdsSelected = (): boolean => {
    const all_ids = this.state.all_ids ? this.state.all_ids : [];
    const selected_ids = this.state.selected_ids ? this.state.selected_ids : [];

    let result: boolean = false;
    if (all_ids.length && selected_ids.length) {
      result = all_ids.every((item: number) => selected_ids.indexOf(item) !== -1);
    }
    return result;
  };

  onDeleteUser = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (e && e.target) {
      this.props.dispatch(
        Actions.ShowModal({
          title: T.t('gen_delete_action'),
          body: T.t('gen_delete_users_question'),
          name: 'user_delete',
          icon: 'warning',
          iconColor: 'red',
          confirm: T.t('gen_yes'),
          cancel: T.t('gen_cancel'),
          onConfirm: () => {
            const resultCallback = (status: number) => {
              if (status == 200) {
                this.state.selected_ids = [];
                this.state.filters = UserSearchInitialValues;
                this.setState(this.state);
                this.searchUsers();
              }
            };

            const deleteList = this.state.selected_ids;
            this.props.dispatch(
              Actions.ApiRequest(Constants.user.USER_DELETE, deleteList, 'user-list-spin', resultCallback)
            );
          }
        })
      );
    }
  };

  onSelectUser = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e && e.currentTarget) {
      let checkedList = Object.assign([], this.state.selected_ids);
      let stringID: string = e.currentTarget.dataset.id || '';
      let id = parseInt(stringID, 10);

      if (e.target.checked) {
        checkedList.push(id);
      } else {
        let index = checkedList.indexOf(id);
        if (index !== -1) {
          checkedList.splice(index, 1);
        }
        this.setState({
          ...this.state,
          filters: {
            ...this.state.filters,
            select_all: false
          }
        });
      }
      this.setState({
        ...this.state,
        selected_ids: checkedList
      });
    }
  };

  static getDerivedStateFromProps(props: Types.IUserPageProps, state: Types.IUserPageState) {
    let hasNewState: boolean = false;
    if (props.match && props.match.params.id) {
      hasNewState = true;
      state.userFormIsOpen = true;
      if (props.match.params.id !== 'create') {
        state.userId = props.match.params.id;
      } else {
        state.userId = undefined;
      }
    } else {
      hasNewState = true;
      state.userFormIsOpen = false;
      state.userId = undefined;
    }

    if (hasNewState) {
      return state;
    } else {
      return null;
    }
  }

  render() {
    const userList = this.props.results;
    return (
      <MainLayout header={<APlanHeader />}>
        <SimplePage name="user-page">
          <div className="main list-campus">
            <div className="container-fluid">
              <h4 className="mb-4 mb-sm-0">{T.t('gen_users')}</h4>
              <Spinner name="user-list-spin" />
              <div className="mt-4 white-container">
                <div className="mb-1 row align-items-center">
                  <div className="col-md-6 col-sm-4 col-12">
                    {this.state.selected_ids && this.state.selected_ids.length ? (
                      <React.Fragment>
                        <button
                          id='button_cencel_selection'
                          className="float-left pl-2 pr-2 category-tag-square tag-gray"
                          style={{ margin: '5px' }}
                          onClick={() => {
                            this.setState({
                              ...this.state,
                              selected_ids: []
                            });
                          }}
                        >
                          <i className="mr-2 material-icons">close</i>
                          <span>
                            {T.t('gen_cancel_selection')} <b>&nbsp;({this.state.selected_ids.length})</b>
                          </span>
                        </button>
                        <button
                          id='button_delete_outline'
                          className="float-left pl-2 pr-2 category-tag-square"
                          style={{ margin: '5px', color: '#fff', backgroundColor: '#dc3545' }}
                          onClick={this.onDeleteUser}
                        >
                          <i className="mr-2 material-icons">delete_outline</i>
                          <span> {T.t('gen_delete_selected')}</span>
                        </button>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <button id='add' className="pl-1 category-tag-square tag-green" onClick={this.userFormIsOpen}>
                          <i className="mr-1 material-icons">add</i>
                          {T.t('gen_add_user')}
                        </button>
                        <ImportModal
                          title={T.t('gen_assigning_head_program_role_with_excel')}
                          componentKey={ExcelImportKeys.Users}
                          dispatch={this.props.dispatch}
                          onImport={this.onUserImported}
                        />
                      </React.Fragment>
                    )}
                  </div>
                  <div className="text-right col-md-6 col-sm-8 col-12">
                    <div className="options d-md-inline-flex d-lg-inline-flex align-items-center">
                      <button
                        id='button_filter'
                        className="float-right ml-3 mr-3 category-tag-square tag-glass"
                        style={{ margin: '5px' }}
                        onClick={() => {
                          this.state.filterIsOpen = !this.state.filterIsOpen;
                          this.setState(this.state);
                        }}
                      >
                        <i className="mr-2 material-icons">filter_list</i>
                        <span>{T.t('gen_filter')}</span>
                      </button>
                    </div>
                  </div>
                </div>
                <div
                  className={`white-container mt-4 collapse ` + (this.state.filterIsOpen ? `show` : ``)}
                  id="advance-search"
                >
                  <div className="mt-3 advance-search d-block" style={{}}>
                    <Formik
                      initialValues={UserSearchInitialValues}
                      enableReinitialize={true}
                      onSubmit={(values, actions) => {
                        this.onFilterUser(values, actions);
                      }}
                      onReset={this.onFormReset}
                    >
                      {(props: FormikProps<Types.IUserPagedQuery>) => {
                        return (
                          <form onSubmit={props.handleSubmit}>
                            <div className="row">
                              <div className="col-12">
                                <h6>{T.t('gen_filter_parameters')}</h6>
                              </div>
                              <div className="col-md-3">
                                <div className="form-input form-group date-picker">
                                  <input
                                    id="username"
                                    name="username"
                                    value={props.values.username}
                                    onChange={props.handleChange}
                                    type="text"
                                    required
                                  />
                                  <span className="highlight" />
                                  <span className="bar" />
                                  <label htmlFor="username">{T.t('gen_username')}</label>
                                  <i className="material-icons">filter_1</i>
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="form-input form-group date-picker">
                                  <input
                                    id="name"
                                    name="name"
                                    value={props.values.name}
                                    onChange={props.handleChange}
                                    type="text"
                                    required
                                  />
                                  <span className="highlight" />
                                  <span className="bar" />
                                  <label htmlFor="name">{T.t('gen_name')}</label>
                                  <i className="material-icons">title</i>
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="form-input form-group date-picker">
                                  <input
                                    id="surname"
                                    name="surname"
                                    value={props.values.surname}
                                    onChange={props.handleChange}
                                    type="text"
                                    required
                                  />
                                  <span className="highlight" />
                                  <span className="bar" />
                                  <label htmlFor="surname">{T.t('gen_surname')}</label>
                                  <i className="material-icons">title</i>
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="form-input form-group date-picker">
                                  <input
                                    id="email"
                                    name="email"
                                    value={props.values.email}
                                    onChange={props.handleChange}
                                    type="text"
                                    required
                                  />
                                  <span className="highlight" />
                                  <span className="bar" />
                                  <label htmlFor="email">{T.t('gen_email')}</label>
                                  <i className="material-icons">title</i>
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="react-select-container">
                                  <label>{T.t('gen_user_type')}</label>
                                  <Select
                                    id='select_role'
                                    className="react-select"
                                    isMulti={true}
                                    closeMenuOnSelect={true}
                                    filterOption={(option: any, query: any) =>
                                      option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                    }
                                    options={AvailableFormRoles(T)}
                                    placeholder={T.t('gen_select_user_type')}
                                    value={props.values.roles_select}
                                    onChange={(
                                      options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                    ) => {
                                      const list: Array<Types.ISelectOption> = options
                                        ? (options as Array<Types.ISelectOption>)
                                        : [];
                                      props.setFieldValue('roles_select', list);
                                      props.setFieldValue(
                                        'roles',
                                        list.map((item) => item.value)
                                      );
                                    }}
                                    noOptionsMessage={(): string => T.t('gen_select_no_role')}
                                  />
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="react-select-container">
                                  <label>{T.t('gen_responsible_faculties')}</label>
                                  <Select
                                    id='select_faculty'
                                    className="react-select"
                                    isMulti={true}
                                    closeMenuOnSelect={false}
                                    options={
                                      this.props.selectOptions && this.props.selectOptions.faculties
                                        ? this.props.selectOptions.faculties
                                        : []
                                    }
                                    placeholder={T.t('gen_select_faculty')}
                                    value={props.values.faculties}
                                    onChange={(
                                      options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                    ) => {
                                      const list: Array<Types.ISelectOption> = options
                                        ? (options as Array<Types.ISelectOption>)
                                        : [];
                                      props.setFieldValue('faculties', list);
                                      props.setFieldValue(
                                        'faculty_ids',
                                        list.map((item) => item.value)
                                      );
                                      this.getFilterProgramsByFacultiesAtUsers(list.map(item => item.value))
                                    }}
                                    noOptionsMessage={(): string => T.t('gen_select_no_faculty')}
                                  />
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="react-select-container">
                                  <label>{T.t('gen_responsible_programs')}</label>
                                  <Select
                                    id='select_program'
                                    className="react-select"
                                    isMulti={true}
                                    closeMenuOnSelect={false}
                                    options={this.props.filter_programs_related_faculty
                                      ? this.props.filter_programs_related_faculty
                                      : []}
                                    placeholder={T.t('gen_select_program')}
                                    value={props.values.programs}
                                    onChange={(
                                      options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                    ) => {
                                      const list: Array<Types.ISelectOption> = options
                                        ? (options as Array<Types.ISelectOption>)
                                        : [];
                                      props.setFieldValue('programs', list);
                                      props.setFieldValue(
                                        'program_of_responsibility_codes',
                                        list.map((item) => item.value)
                                      );
                                    }}
                                    noOptionsMessage={(): string => T.t('gen_select_no_program')}
                                  />
                                </div>
                              </div>

                              {this.props.general_settings && this.props.general_settings.event_module_status === SettingsEventModuleStatus.Active ? (
                                <div className="col-md-3">
                                  <div className="react-select-container">
                                    <label>{T.t('gen_user_used_classroom')}</label>
                                    <Select
                                      id='select_user_used_classroom'
                                      className="react-select"
                                      isMulti={true}
                                      closeMenuOnSelect={false}
                                      options={
                                        this.props.selectOptions && this.props.selectOptions.classrooms
                                          ? this.props.selectOptions.classrooms
                                          : []
                                      }
                                      placeholder={T.t('gen_select_classroom')}
                                      value={props.values.classrooms}
                                      onChange={(
                                        options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                      ) => {
                                        const list: Array<Types.ISelectOption> = options
                                          ? (options as Array<Types.ISelectOption>)
                                          : [];
                                        props.setFieldValue('classrooms', list);
                                        props.setFieldValue(
                                          'classroom_codes',
                                          list.map((item) => item.value)
                                        );
                                      }}
                                      noOptionsMessage={(): string => T.t('gen_select_no_classroom')}
                                    />
                                  </div>
                                </div>
                              ) : null}

                            </div>
                            <hr />
                            <div className="mt-3 row">
                              <div className="col-6">
                                <button
                                  id='button_arrow_upward'
                                  type="button"
                                  onClick={() => {
                                    this.state.filterIsOpen = false;
                                    this.setState(this.state);
                                  }}
                                  className="mt-2 mb-2 mr-2 mw-none mt-md-0 mb-md-0 btn-gray btn"
                                >
                                  <i className="material-icons">arrow_upward</i>
                                </button>
                                <button
                                  id='button_delete_sweep'
                                  type="reset"
                                  onClick={props.handleReset}
                                  className="mt-2 mb-2 mw-none btn btn-danger mt-md-0 mb-md-0"
                                >
                                  <i className="material-icons">delete_sweep</i>
                                </button>
                              </div>
                              <div className="text-right col-6">
                                <button
                                  id='button_search'
                                  type="button"
                                  className="mt-2 mb-2 btn btn-blue mt-md-0 mb-md-0"
                                  onClick={() => props.handleSubmit()}
                                  disabled={props.isSubmitting}
                                >
                                  <i className="mr-2 material-icons">search</i> {T.t('gen_search')}
                                </button>
                              </div>
                            </div>
                          </form>
                        );
                      }}
                    </Formik>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <table className="table aplan-table aplan-table-responsive table-borderless table-striped table-hover sortable filter-table">
                      <thead>
                        <tr>
                          <th data-cell="select">
                            <div className="tick-radio position-relative">
                              <input
                                id='select_all'
                                type="checkbox"
                                className="form-radio"
                                checked={this.checkAllIdsSelected()}
                                onChange={this.onSelectAll}
                              />
                            </div>
                          </th>
                          <SortedColumn
                            datacell="status"
                            title={T.t('gen_status')}
                            sortkey="status"
                            sortedcolumn={this.state.filters.order_by}
                            sort={this.sort}
                          />
                          <SortedColumn
                            datacell="name"
                            title={T.t('gen_name')}
                            sortkey="name"
                            sortedcolumn={this.state.filters.order_by}
                            sort={this.sort}
                          />
                          <SortedColumn
                            datacell="surname"
                            className="d-none d-lg-table-cell d-xl-table-cell"
                            title={T.t('gen_surname')}
                            sortkey="surname"
                            sortedcolumn={this.state.filters.order_by}
                            sort={this.sort}
                          />
                          <SortedColumn
                            datacell="username"
                            className="d-none d-lg-table-cell d-xl-table-cell"
                            title={T.t('gen_username')}
                            sortkey="username"
                            sortedcolumn={this.state.filters.order_by}
                            sort={this.sort}
                          />
                          <SortedColumn
                            datacell="email"
                            className="d-none d-lg-table-cell d-xl-table-cell"
                            title={T.t('gen_email')}
                            sortkey="email"
                            sortedcolumn={this.state.filters.order_by}
                            sort={this.sort}
                          />
                          <SortedColumn
                            datacell="role"
                            className="d-none d-lg-table-cell d-xl-table-cell"
                            title={T.t('gen_user_type')}
                            sortkey="role"
                            sortedcolumn={this.state.filters.order_by}
                            sort={this.sort}
                          />
                          <th scope="col" className="text-center">
                            <span>{T.t('gen_responsible_faculties')}</span>
                          </th>
                          <th scope="col" className="text-center">
                            <span>{T.t('gen_responsible_programs')}</span>
                          </th>
                          <th scope="col" className="text-center">
                            <span>{T.t('gen_user_used_classroom')}</span>
                          </th>
                          <th scope="col" className="text-right">
                            <span className="text-right">{T.t('gen_actions')}</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {userList && userList.length
                          ? userList.map((item: Types.IUser) => (
                            <tr key={'user-1' + item.user_id}>
                              <td data-cell="select">
                                {
                                  item.username === 'system' ? null :
                                    <div className="tick-radio position-relative">
                                      <input
                                        id='select_user'
                                        type="checkbox"
                                        className="form-radio"
                                        checked={
                                          this.state.selected_ids &&
                                          this.state.selected_ids.indexOf(item.user_id ? item.user_id : -1) > -1
                                        }
                                        data-id={item.user_id}
                                        onChange={this.onSelectUser}
                                      />
                                    </div>
                                }
                              </td>
                              <td scope="row" data-label={T.t('gen_status')}>
                                <div className="ml-1 mr-4 tags">
                                  <button
                                    id='status'
                                    className={
                                      `small-tag text-uppercase` + (item.status == 1 ? ` tag-green` : ` tag-red`)
                                    }
                                  >
                                    {item.status == 1 ? 'AKTİF' : 'PASİF'}
                                  </button>
                                </div>
                              </td>
                              <td scope="row" data-label={T.t('gen_name')}>
                                {item.name}
                              </td>
                              <td data-label={T.t('gen_surname')}>{item.surname}</td>
                              <td data-label={T.t('gen_username')}>{item.username}</td>
                              <td data-label={T.t('gen_email')}>{item.email}</td>
                              <td data-label={T.t('gen_user_type')}>{DisplayRoleLabel(item.role, T)}</td>
                              <td className="text-center">
                                {item.faculty_of_responsibilities && item.faculty_of_responsibilities.length > 0 ?
                                  <div className="table-scrollable-td">{item.faculty_of_responsibilities && item.faculty_of_responsibilities.map((i: any, index: any) => (index == item.faculty_of_responsibilities!.length - 1) ? <>{i ? i.label : '-'}<br /></> : <>{i ? i.label : '-'},<br /></>)}</div> : '-'
                                }
                              </td>
                              <td className="text-center">
                                {item.program_of_responsibilities && item.program_of_responsibilities.length > 0 ?
                                  <div className="table-scrollable-td">{item.program_of_responsibilities && item.program_of_responsibilities.map((i: any, index: any) => (index == item.program_of_responsibilities!.length - 1) ? <>{i ? i.label : '-'}<br /></> : <>{i ? i.label : '-'},<br /></>)}</div> : '-'
                                }
                              </td>
                              <td className="text-center" data-label={T.t('gen_user_used_classroom')}>{item.used_classroom && item.used_classroom.label}</td>
                              <td data-label={T.t('gen_actions')} className="table-buttons">
                                {
                                  this.props.userInfo.name === 'system' && item.username === 'system' ?
                                    <div className="table-buttons-wrapper">
                                      <button
                                        id='button_edit'
                                        data-toggle="tooltip"
                                        data-id={item.user_id}
                                        onClick={this.onUserEdit}
                                        title={T.t('gen_edit')}
                                        className="btn btn-light btn-sm table-button"
                                      >
                                        <span className="d-block" data-toggle="modal" data-target="#addNew">
                                          <i className="material-icons">edit</i>
                                        </span>
                                      </button>
                                    </div>
                                    : this.props.userInfo.name !== 'system' && item.username === 'system' ? null
                                      :
                                      <div className="table-buttons-wrapper">
                                        <button
                                          id='edit'
                                          data-toggle="tooltip"
                                          data-id={item.user_id}
                                          onClick={this.onUserEdit}
                                          title={T.t('gen_edit')}
                                          className="btn btn-light btn-sm table-button"
                                        >
                                          <span className="d-block" data-toggle="modal" data-target="#addNew">
                                            <i className="material-icons">edit</i>
                                          </span>
                                        </button>
                                      </div>
                                }
                              </td>
                            </tr>
                          ))
                          : null}
                      </tbody>
                    </table>
                    <div className="row-options justify-content-end">
                      <div
                        className="page-sorting d-flex align-items-center justify-content-center"
                        style={{ marginTop: '5px' }}
                      >
                        {this.props.results && this.props.results.length > 0 && (
                          <Paginate filters={this.props.filters} onPageChange={this.onPageChange} />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SimplePage>
        <UserForm userId={this.state.userId} formIsOpen={this.state.userFormIsOpen} onClose={this.userFormOnClose} />
      </MainLayout>
    );
  }
}

const mapStateToProps = (store: Types.IPersistedState, ownProps: Types.IUserPageProps): Types.IUserPageProps => {
  if (!store) {
    return ownProps;
  }
  const newProps: Types.IUserPageProps = Object.assign({}, ownProps, {
    term_id: store.state.term_id,
    results: store.state.user_pages && store.state.user_pages.results,
    filters: store.state.user_pages && store.state.user_pages.filters,
    selectOptions: store.state.select_options && store.state.select_options.userPage,
    filter_programs_related_faculty: store.state.select_options && store.state.select_options.filter_programs_related_faculty,
    userInfo: store.state.user,
    general_settings: store.state.general_settings
  });
  return newProps;
};

const equal = require('deep-equal');
const areStatesEqual = (next: Types.IPersistedState, prev: Types.IPersistedState) => {
  let nextResults = next.state ? next.state : [];
  let prevResults = prev.state ? prev.state : [];
  if (nextResults) {
    return !!equal(prevResults, nextResults);
  } else {
    return true;
  }
  return false;
};

const dispatchProps = (dispatch: any) => ({ dispatch });

const container = connect(mapStateToProps, dispatchProps, null, {
  areStatesEqual
})(UserTable);

export default container;
